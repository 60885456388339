import React, { useState, useRef, useLayoutEffect } from "react";
import secondData from "../data/secondData";
import "../../styles/second.css";
import LeadForm from "./LeadForm";

const Second = () => {
  const data = secondData();
  const [show, doShow] = useState(false);
  const ourRef = useRef(null);
  useLayoutEffect(() => {
    const topPos = (element) => element.getBoundingClientRect().top;
    const div1Pos = topPos(ourRef.current);

    const onScroll = () => {
      const scrollPos = window.scrollY + window.innerHeight;

      if (div1Pos < scrollPos) {
        doShow(true);
      } else {
        doShow(false);
      }
    };

    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);
  return (
    <section
      className={`second common__section ${show && "active"}`}
      id="second"
      ref={ourRef}
    >
      <div className="container">
        <div className="rightBlock blocks">
          <h2>{data.titleRight}</h2>
          <p>{data.textRight}</p>
          <LeadForm />
        </div>
      </div>
    </section>
  );
};

export default Second;
