export default function whyWe() {
  const colors = ["#25c560", "#eac433"]
  return {
    title: "Преимущества BATDU",
    descr:
      "",
    list: [
      {
        text: "Компания BATDU работает без предоплаты. Мы получаем вознаграждение исключительно после того как Вы получили свои деньги",
        color: colors[0],
      },
      {
        text: "Всю работу по возврату Ваших средств мы берем на себя. Вам достаточно предоставить нам необходимую информацию и подписать договор",
        color: colors[1],
      },
      {
        text: "BATDU берется за возврат даже довольно скромных сумм. Мы поможем вернуть Ваши деньги даже если речь идет о сумме в 1000-2000 долларов",
        color: colors[0],
      },
      {
        text: "BATDU работает крайне эффективно. До 96% дел за которые мы беремся заканчиваются возвратом денег нашим клиентам",
        color: colors[1],
      },
    ],
    subtitle: "",
    subdescr: ""
  };
}
