import React, { useState, useRef, useLayoutEffect } from "react";
import whyWe from "../data/benefits";
import "../../styles/benefits.css";

const Benefits = () => {
  const [show, doShow] = useState(false);
  const ourRef = useRef(null);

  useLayoutEffect(() => {
    const topPos = (element) => element.getBoundingClientRect().top;
    const div1Pos = topPos(ourRef.current);

    const onScroll = () => {
      const scrollPos = window.scrollY + window.innerHeight;

      if (div1Pos < scrollPos) {
        doShow(true);
      } else {
        doShow(false);
      }
    };

    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const data = whyWe();

  return (
    <section
      className={`benefits common__section ${show && "active"}`}
      id="benefits"
      ref={ourRef}
    >
      <div className="innerSection">
        <div className="imageBlock"></div>
        <div className="textBlock">
          <h2>{data.title}</h2>
          {/* <p>{data.descr}</p> */}
          <ul className="list">
            {data.list.map((e, i) => (
              <li key={i} style={{ borderColor: e.color }}>
                {e.text}
              </li>
            ))}
          </ul>
          {/* <h4>{data.subtitle}</h4>
        <p>{data.subdescr}</p> */}
        </div>
      </div>
    </section>
  );
};

export default Benefits;
