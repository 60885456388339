export default function whyWe() {
  const colors = ["#895cc3", "#eac433"]
  return {
    title: "Как происходит возврат денег",
    descr:
      "BATDU берет на себя практически весь комплекс мер для возврата денег клиентов. С каким бы видом мошенничества Вы не столкнулись, возврат средств пройдет для Вас довольно просто, в несколько несложных этапов:",
    list: [
      {
        text: "Оставьте свои контактные данные, с Вами свяжется специалист BATDU и предоставит абсолютно бесплатную первичную консультацию",
        color: colors[0],
      },
      {
        text: "Заключается договор на оказание услуги по возврату Ваших денег",
        color: colors[1],
      },
      {
        text: "BATDU проводит всю необходимую подготовку для начала возврата Ваших средств. Собирается вся необходимая информация и готовятся документы",
        color: colors[0],
      },
      {
        text: "Начинается возврат Ваших денег с применением всех необходимых судебных и внесудебных инструментов",
        color: colors[1],
      },
      {
        text: "Вы получаете назад свои деньги, после чего BATDU получает вознаграждение за оказанные услуги ",
        color: colors[0],
      },
    ],
    subtitle: "",
    subdescr: ""
  };
}
