import React, {useState, useRef, useLayoutEffect} from "react";
import bitcoin from "../../images/bitcoin.svg";
import returnFundsData from "../data/returnFunds";
import "../../styles/return.css";

const ReturnFunds = ({ onBackDropClick }) => {
  const returnFunds = returnFundsData();
  const [show, doShow] = useState(false);
  const ourRef = useRef(null);
  useLayoutEffect(() => {
    const topPos = (element) => element.getBoundingClientRect().top;
    const div1Pos = topPos(ourRef.current);

    const onScroll = () => {
      const scrollPos = window.scrollY + window.innerHeight;

      if (div1Pos < scrollPos) {
        doShow(true);
      } else {
        doShow(false);
      }
    };

    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);
  return (
    <section
      className={`common__section returnFunds ${show && "active"}`}
      id="return-funds"
      ref={ourRef}
    >
      {/* <img src={returnBg} alt="section background"/> */}
      <div className="container">
        <div className="returnFunds__inner">
          <div className="returnFundsIMG">
            <img src={bitcoin} alt="" />
          </div>
          <div className="textBlock">
            <h3 className="my-4">{returnFunds.title}</h3>
            <p>{returnFunds.desc}</p>
            <p>{returnFunds.desc2}</p>
          </div>
        </div>
        <div className="banner-btn">
          <button
            className="btn-primary"
            onClick={() => {
              onBackDropClick();
            }}
          >
            {returnFunds.btn}
          </button>
        </div>
      </div>
    </section>
  );
};

export default ReturnFunds;
