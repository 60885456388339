import logo from "../../images/logoNewLight.svg";
export default function slidesData() {
  return {
    // title: "****",
    firstBlock: "Пришло время вернуть Ваши деньги",
    secondBlock:
      "Мошенники украли Ваши деньги? Фейковые биржи, брокеры или букмекеры присвоили Ваши деньги? Вернем Ваши деньги оперативно и без предоплаты.",
    btn: "Приступить к возврату",
    logo: logo,
  };
}