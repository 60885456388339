import React, { useState, useRef, useLayoutEffect, useContext } from "react";
import howTo from "../data/howToStartData";
import "../../styles/howToStart.css";
import CommonContext from "../../js/data/Context";

const HowToStart = () => {
  const [show, doShow] = useState(false);
  const ourRef = useRef(null);
  const global = useContext(CommonContext);
  const colors = ["#25c560", "#895cc3"];
  useLayoutEffect(() => {
    const topPos = (element) => element.getBoundingClientRect().top;
    const div1Pos = topPos(ourRef.current);

    const onScroll = () => {
      const scrollPos = window.scrollY + window.innerHeight;

      if (div1Pos < scrollPos) {
        doShow(true);
      } else {
        doShow(false);
      }
    };

    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const data = howTo();
  const liStyle = (i) => {
    let wd = 45;
    let color = i === 0 || i===3 ? colors[0] : colors[1];
    if (global.wd <= 450) {
      wd = 100;
      color = i % 2 === 0 ? colors[0] : colors[1];
    }
    if (global.wd >= 800 && global.hd <= 450) {
      wd = 48;
    }
    const del = i === 0 ? 0.25 : i;
    return {
      borderColor: color,
      width: `${wd}%`,
      animationDelay: `${del}s`,
    };
  };
  return (
    <section
      className={`howToStart common__section ${show && "active"}`}
      id="howToStart"
      ref={ourRef}
    >
      <div className="container">
        <h2>{data.title}</h2>
        <p>{data.descr}</p>
        <ul className="list">
          {data.list.map((e, i) => (
            <li key={i} style={liStyle(i)}>
              {e.text}
              <p className="number">{i + 1}</p>
            </li>
          ))}
        </ul>
        {/* <p className="afterText">{data.afterText}</p>        */}
      </div>
    </section>
  );
};

export default HowToStart;
