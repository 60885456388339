import React, { useLayoutEffect, useState, useRef, useContext } from "react";
import CommonContext from "../../js/data/Context";
import "../../styles/banner.css";
import bannerData from "../data/bannerData";

const Banner = ({  onBackDropClick }) => {
  const data = bannerData();
  const global = useContext(CommonContext);
  const containerRef = useRef();
  const sectionRef = useRef();
  const [dimensions, setDimensions] = useState({ height: 0 });

  useLayoutEffect(() => {
    if (containerRef.current && sectionRef.current) {
      setDimensions({       
        height: (sectionRef.current.offsetHeight - containerRef.current.offsetHeight)/2 
      });
    }
  }, []);
  const contStyle = () => {
    if (global.wd >= 1200) {
      return {
        margin: `${dimensions.height}px auto`
      };
    }
    return null;
  }
  return (
    <section className="banner" id="home" ref={sectionRef}>
      <div className="bannerInner">
        <div className="container" ref={containerRef} style={contStyle()}>
          <div className="bannerLogo">
            <img src={data.logo} alt="logo" />
          </div>
          <div className="bannerText">
            <h1>{data.firstBlock}</h1>
            {data.secondBlock ? (
              <p className="banner-descr">{data.secondBlock}</p>
            ) : null}
          </div>
          <div className="banner-btn">
            <button
              className="btn-primary"
              onClick={() => {
                onBackDropClick();
              }}
            >
              {data.btn}
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
