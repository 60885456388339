export default function howToStartData() {
  const colors = ["#731425", "#0d0d0d"];
  return {
    title: "С чем мы чаще всего сталкиваемся",
    descr:
      "Для Вас ситуация безусловно уникальна, но за годы практики наши специалисты получили опыт возврата денег практически во всех случаях мошенничества.",
    list_title: "Чаще всего люди обращаются к нам со следующими проблемами:",
    list: [
      {
        text: "Слив или блокирование депозита трейдера. Чаще всего этим занимаются так называемые “кухни”. Мы поможем вернуть деньги и вывести депозит",
        color: colors[0],
      },
      {
        text: "Обвал финансовой пирамиды. В момент обвала финансовая пирамида все еще имеет деньги, BATDU поможет Вам вернуть свой вклад",
        color: colors[0],
      },
      {
        text: "Заморозка выигрыша в онлайн-казино или брокерской конторе. Окажем давление на аферистов и выведем Вашим деньги",
        color: colors[1],
      },
      {
        text: "Обманула криптовалютная площадка. Возвращаем криптовалютные транзакции независимо от вида аферы  ",
        color: colors[0],
      },


    ],
    afterText: "",
  };
}